@import "start";
.link-phone:hover {
	.fas.fa-phone:after {
		background-color: $accent; } }
.fas.fa-phone {
	position: relative;
	font-size: 14px;
	color: #fff;
	&:after {
		content: '';
		position: absolute;
		top: -9px;
		left: -9px;
		width: 32px;
		height: 32px;
		background-color: #2a3b80;
		border-radius: 50%;
		z-index: -1;
		transition: .3s ease; } }

.section-banner {
	background-image: url(../img/content/banner.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 1.5625rem 0; }
.banner-container {
	display: grid;
	grid-template-columns: auto 45%;
	grid-column-gap: 3.125rem; }
.banner-subtitle {
	font-size: 1.5625rem;
	font-weight: 500; }
.banner-text {
	padding-top: 12%; }
form {
	font-family: Gilroy;
	font-weight: 500; }
input.form-control {
	box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.03);
	height: calc(2.8em + 0.75rem + 2px);
	border-radius: 6px;
	background-color: #f7f7f7;
	text-align: left; }
textarea.form-control {
	background-color: #f7f7f7;
	box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.03); }
.form-block {
	padding: 2.5rem 3.125rem;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 3px 20px 4px rgba(0, 0, 0, 0.07); }
.form-title {
	font-size: 1.6875rem;
	font-weight: bold;
	line-height: 2.375rem; }
.form-group {
	margin-bottom: 1.3rem; }
.form-header {
	opacity: 0.8;
	font-size: 16px; }
.form-footer {
	opacity: 0.8;
	font-size: 0.875rem; }

.section-cleaning {
	background-image: url(../img/content/cleaning.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 4.375rem 0 12.5rem; }
.title-question {
	font-size: 1.875rem;
	font-weight: bold; }
.text-accent-big {
	font-size: 1.5625rem;
	font-weight: bold;
	color: $accent; }

.section-slider {
	padding-top: 6.25rem; }
.slide-photo {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat; }
.main-slider {
	padding-top: 5rem!important; }


.section-unlimited {
	background-image: url(../img/content/bg-opacity.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat; }

.section-catalog {
	padding-bottom: 70px; }
.gallery-item {
	margin-bottom: 1.5625rem;
	&:hover {
		.gallery-item__photo {
			transform: scale(1.1); } }
	a.embed-responsive-item {
		border-radius: 10px;
		overflow: hidden; }
	&__photo {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		cursor: zoom-in;
		transition: .3s ease;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden; }
	&__title {
		text-align: center;
		margin-top: 10px; } }
.gallery-item__text-desc {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-style: italic;
	font-weight: bold; }
.gallery-item--no-photo {
		box-shadow: 0 3px 20px 4px rgba(0, 0, 0, 0.07);
		border-radius: 10px; }

.section-catalog2 {
	background-image: url(../img/content/bg-op2.png);
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat; }

.section-before-after {

	.embed-responsive-item {
		height: 300px; } }
.twentytwenty-wrapper {
	transform: skew(175deg);
	overflow: hidden;
	width: 80%;
	margin: auto;
	border-radius: 15px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 2%;
		top: 2%;
		width: 96%;
		height: 96%;
		border: 1px solid #ffffff;
		border-radius: 15px;
		z-index: 50;
		pointer-events: none; } }
.twentytwenty-container {
	background-color: #90d39f;
	transition: .3s ease;
	transform: skew(185deg);
	position: relative;
	left: -2%;
	width: 104%;
	img {
		width: 100%;
		height: 450px; } }
.twentytwenty-before-label, .twentytwenty-after-label {
	display: none; }
.twentytwenty-overlay {
	display: none; }

.before-after__text {
	padding: 0 10%;
	font-size: 50px;
	font-family: $h-font;
	font-weight: bold;
	padding: 0.9375rem 0;
	span {
		background-image: linear-gradient(-251deg, #3250c8 0%, #20ca46 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent; } }

.section-form {
	background-image: url(../img/content/popup-bg.png);
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat; }

.garant {}
.animate-number {
	display: flex;
	flex-direction: column;
	align-items: center; }
.about-num {
	font-weight: bold;
	display: flex;
	font-family: $h-font;
	justify-content: center;
	align-items: flex-end;
	position: relative; }
// .about-num.pr-5, .about-num.pr-0
// 	.about-num__desc
// 		right: 0
.about-num__desc {
	transform: rotate(-90deg);
	transform-origin: -10px;
	display: inline-block;
	font-size: 32px;
	position: absolute;
	right: -2.5rem;
	white-space: nowrap;
	width: 10px; }
span.calc__text-small {
	font-size: 1.9375rem;
	background-image: linear-gradient(-251deg, #3250c8 0%, #20ca46 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	line-height: 2.6;
	position: relative;
 }	// left: .8rem

.about-text {
	height: 4.375rem;
	text-align: center;
	font-weight: bold;
	font-size: 1.0625rem; }
.calc {
	font-size: 160px;
	line-height: 1;
	background-image: linear-gradient(-251deg, #3250c8 0%, #20ca46 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	white-space: nowrap; }


.company-list {}
.company-item {
	position: relative;
	padding-bottom: 30px;
	&__photo.company-item__photo--long {
		background-size: auto 70%; }
	&__photo {
		background-size: 60%;
		background-position: center;
		background-repeat: no-repeat; }
	&:after {
		content: '';
		box-shadow: 0 3px 20px 4px rgba(0, 0, 0, 0.07);
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 30px;
		transform: skew(160deg); } }

.steps-block {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
.step-item__icon {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	min-height: 6.25rem;
	margin-bottom: 1.5rem; }

.manager-block {
	position: relative;
	width: 85%;
	&:before {
		content: '';
		position: absolute;
		box-shadow: 0 2px 18px 3px rgba(0, 0, 0, 0.07);
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 100%;
		transform: skew(160deg); } }
.manager-block__position {
	font-size: 1.25rem; }
.manager-block__name {
	font-size: 1.375rem; }

footer {
	box-shadow: 0 0 18px 1px rgba(0, 0, 0, 0.11); }

.modal-body {
	background-image: linear-gradient(-251deg, #3250c8 0%, #20ca46 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; }

// .videoWrapper
//   position: relative
//   width: 100%
//   height: 0
//   background-color: #000
//   &43
//     padding-top: 75%

//   &169
//     padding-top: 56%


// .videoIframe
//   position: absolute
//   top: 0
//   right: 0
//   bottom: 0
//   left: 0
//   width: 100%
//   height: 100%
//   background-color: transparent

// .videoPoster
//   position: absolute
//   top: 0
//   right: 0
//   left: 0
//   width: 100%
//   height: 100%
//   margin: 0
//   padding: 0
//   cursor: pointer
//   border: 0
//   outline: none
//   background-position: 50% 50%
//   background-size: 100% 100%
//   background-size: cover
//   text-indent: -999em
//   overflow: hidden
//   opacity: 1
//   -webkit-transition: opacity 800ms, height 0s
//   -moz-transition: opacity 800ms, height 0s
//   transition: opacity 800ms, height 0s
//   -webkit-transition-delay: 0s, 0s
//   -moz-transition-delay: 0s, 0s
//   transition-delay: 0s, 0s
//   &:before
//     content: ''
//     position: absolute
//     top: 50%
//     left: 50%
//     width: 80px
//     height: 80px
//     margin: -40px 0 0 -40px
//     border: 5px solid $color-button
//     border-radius: 100%
//     -webkit-transition: border-color 300ms
//     -moz-transition: border-color 300ms
//     transition: border-color 300ms

//   &:after
//     content: ''
//     position: absolute
//     top: 50%
//     left: 50%
//     width: 0
//     height: 0
//     margin: -20px 0 0 -10px
//     border-left: 40px solid $color-button
//     border-top: 25px solid transparent
//     border-bottom: 25px solid transparent
//     -webkit-transition: border-color 300ms
//     -moz-transition: border-color 300ms
//     transition: border-color 300ms

//   &:hover,
//   &:focus
//     &:before
//       border-color: $color-button-hover

//     &:after
//       border-left-color: $color-button-hover


//   .videoWrapperActive &
//     opacity: 0
//     height: 0
//     -webkit-transition-delay: 0s, 800ms
//     -moz-transition-delay: 0s, 800ms
//     transition-delay: 0s, 800ms




@import "media";

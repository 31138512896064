@import "vars";
@import "mixins/mixins";
@import "libs/libs";
@import "modules/modules";

html {
	@include fluid-type($min_width, $max_width, $min_font, $max_font);
	height: 100%; }

body {
	height: 100%;
	font-family: $d-font;
	color: $text; }
h1 {
	font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
	@include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2*$mod_2*$mod_2 *$min_font); }

h2 {
	font-size: $mod_1*$mod_1*$mod_1 *0.5rem;
	@include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1*$mod_1*$min_font, $mod_2*$mod_2*$mod_2*$mod_2 *$min_font); }

h3 {
	font-size: $mod_1*$mod_1 *1rem;
	@include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font); }

h4 {
	font-size: $mod_1*$mod_1*$mod_1 *0.5rem;
	@include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font); }

h5 {
	font-size: $mod_1*$mod_1 *1rem;
	@include fluid-type($min_width, $max_width, $mod_1 *$min_font, $mod_2 *$min_font); }

h1, h2, h3, h4, h5, h6 {
	margin-bottom: .5em;
	font-weight: 700;
	line-height: 1.2;
	font-family: $h-font; }

.subtitle {
	font-size: 1.1875rem;
	font-weight: 500;
	line-height: 1.75rem;
	text-align: center; }

.text-small {
	font-size: 0.875rem; }

.text-big {
	font-size: 1.125rem; }

.b {
	font-weight: bold; }

a {
	text-decoration: none; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
	vertical-align: middle; }

textarea {
	resize: none; }

input,
select,
button {
	outline: none; }

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%; }

.content {
	flex: 1 0 auto;
	overflow-x: hidden; }
section {
	position: relative;
	padding: 4.375rem 0; }
